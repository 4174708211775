import useLogin from "@/hooks/useLogin";

export const defaultPlatform: PlatformInterface = {
  setOnExamEnter(): Promise<string> {
    return Promise.resolve("");
  },
  getVolume(): Promise<number> {
    return new Promise((resolve) => {
      resolve(60);
    });
  },
  setVolume(): Promise<void> {
    return new Promise((resolve) => {
      resolve();
    });
  },
  getMicVolume(): Promise<number> {
    return Promise.resolve(60);
  },
  closeApp(): Promise<string> {
    return Promise.resolve("");
  },
  setMicVolume(): Promise<void> {
    return Promise.resolve();
  },
  getMac(): Promise<string> {
    return Promise.resolve("AAAAAAAAAA");
  },
  ready(): Promise<void> {
    return Promise.resolve();
  },
  registerHandles(): void {
    const { loginFromToken } = useLogin();
    const token =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vY29tbW9uLXNlcnZpY2UvYXV0aC9sb2dpbiIsImlhdCI6MTY0MjY2NjA5NSwiZXhwIjoxNjQzMjcwODk1LCJuYmYiOjE2NDI2NjYwOTUsImp0aSI6IjJrNVYxNHVvZmNsS051QloiLCJzdWIiOjQwNSwicHJ2IjoiOWM0MjllNmE2MGNkNTI4NTQ3M2YyYzhiYzcwMWVjMDk0OGRmNGQ4YyIsImd1YXJkIjoic3R1ZGVudCJ9.jSjkb6l-m4MLcV-4VAakuGebRBx5FXrRXrPK3JF_bUk";
    document.addEventListener(
      "login",
      async () => {
        await loginFromToken(token, 1);
      },
      {
        once: true,
      }
    );
  },
  setMuted: function (): Promise<void> {
    return new Promise<void>((resolve) => {
      resolve();
    });
  },
  listenProctorConfig(): void {
    return;
  },
  getFluidsynthJsSoundFont(): Promise<any> {
    return new Promise<any>((resolve) => {
      resolve("");
    });
  },
};
