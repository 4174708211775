import _ from "lodash";
import { GetterTree } from "vuex";

export const getters: GetterTree<State, State> = {
  allPaperQuestionsStatistics(state) {
    const papers = (state.papers as any) || [];
    return papers.map((paper: any) => {
      const { parperParts = {} } = paper as any;
      const { parts = [] } = parperParts as any;
      const questions = _.flatten(
        parts.map((part: any) => part.questions) || []
      );
      const total_answer_question_num = questions.filter((question: any) =>
        question.type !== 11
          ? question.answer_params
          : question.sub_questions
              .map((sub: any) => sub.answer_params)
              .filter(Boolean).length === question.sub_questions.length
      ).length;
      return {
        ...paper,
        total_answer_question_num,
      };
    });
  },
  allMockPaperQuestionsStatistics(state) {
    const papers = (state.papers as any) || [];
    return papers.map((paper: any) => {
      const { parperParts = {} } = paper as any;
      const { parts = [] } = parperParts as any;
      const questions = _.flatten(
        parts.map((part: any) => part.questions) || []
      );
      console.log("questions", questions);
      const total_answer_question_num = questions.filter((question: any) =>
        question.type !== 11
          ? question.answer_params
          : question.sub_questions
              .map((sub: any) => sub.answer_params)
              .filter(Boolean).length
      ).length;
      return {
        ...paper,
        total_answer_question_num,
      };
    });
  },
};
