import { useRouter } from "vue-router";
import { MutationNames, useStore } from "@/store";
import {
  studentLogin,
  getStudentInfo,
  getCurrentDuringExam,
  getSchoolPcSoftwareUpgradeInfo,
} from "@/services";
import { LoginType } from "@/types";
import { ElLoadingService, ElLoading } from "element-plus";
import useExam from "@/hooks/useExam";
import useUpLoadResource from "./useUpLoadResource";
import { updateExamStatus } from "@/services/proctor";

export default () => {
  const { getExamPaper, showExamMessageBox } = useExam();
  const store = useStore();
  const router = useRouter();
  const { destoryOssResource } = useUpLoadResource();

  const login = async (
    { account, password }: { account: string; password: string },
    path?: string
  ) => {
    if (!account || !password) {
      console.error(" no account or password");
      return;
    }
    destoryOssResource();
    const hardwareModel = store.state.hardwareModel;
    const loginInfo = await studentLogin({ account, password });
    console.log("loginInfo", loginInfo);
    if (!loginInfo) {
      return;
    }
    const Token = loginInfo.access_token;
    await loginFromToken(Token, LoginType.Account, path);
    store.commit(MutationNames.CHANGE_ACCOUNT, account);
    updateExamStatus();
    // await getSchoolPcSoftwareUpgradeInfo({
    //   hardware_model: "win64",
    //   type: 10,
    // }).then((res: any) => {
    //   console.log("res", res);
    //   if (res.file_url !== "") {
    //     const fileUrl = res.file_url;
    //     // const { ipcRenderer } = window.require("electron");
    //     console.log("向electron发送信息newVersion", fileUrl);
    //     // ipcRenderer.sendToHost("newVersion", fileUrl); // 向原生发送升级信息
    //   }
    // });
    // pc端 检查该账号是否有可升级的安装包
    if (window.require) {
      if (hardwareModel) {
        await getSchoolPcSoftwareUpgradeInfo({
          hardware_model: hardwareModel,
          type: 10,
        }).then((res: any) => {
          console.log("res", res);
          if (res.file_url !== "") {
            const fileUrl = res.file_url;
            const { ipcRenderer } = window.require("electron");
            console.log("向electron发送信息newVersion", fileUrl);
            ipcRenderer.sendToHost("newVersion", fileUrl); // 向原生发送升级信息
          }
        });
      }
    }
    ElLoading.service({ background: "rgb(0,0,0,0.0)" }).close();
  };
  const gradeName: { [key: number]: string } = {
    1: "一年级",
    2: "二年级",
    3: "三年级",
    4: "四年级",
    5: "五年级",
    6: "六年级",
    7: "七年级",
    8: "八年级",
    9: "九年级",
    10: "高一",
    11: "高二",
    12: "高三",
  };

  const loginFromToken = async (
    token: string,
    loginType: LoginType,
    path?: string
  ) => {
    destoryOssResource();
    console.log("path", path);
    localStorage.setItem("token", token);
    store.commit(MutationNames.CHANGE_TOKEN, token);
    if (!store.state.token) {
      return;
    }
    const studentInfo = await getStudentInfo();

    console.log("studentInfo", studentInfo);
    if (studentInfo) {
      store.commit(MutationNames.CHANGE_LOGIN_TYPE, loginType);
      store.commit(MutationNames.CHANGE_NAME, studentInfo.name);
      store.commit(MutationNames.CHANGE_SCHOOLNAME, studentInfo.school_name);
      store.commit(MutationNames.CHANGE_GRADE, gradeName[studentInfo.grade]);
      store.commit(MutationNames.CHANGE_CLASSNUMBER, studentInfo.class_name);
      store.commit(MutationNames.CHANGE_STUDENTID, studentInfo.id);
    }
    await getCurrentDuringExam().then((data: any) => {
      console.log(11, data);
      if (data.during_exam !== null) {
        const examInfo = {
          active_exam: {
            id: data.during_exam.id,
            name: data.during_exam.name,
            time: data.during_exam.time,
            start_time: data.during_exam.start_time,
            exam_time_type: data.during_exam.exam_time_type,
            question_order_type: data.during_exam?.question_order_type,
          },
          answer_sheet: data.answer_sheet,
        };
        store.commit(MutationNames.CHANGE_EXAMINFO, examInfo);
        store.commit(MutationNames.CHANGE_ISDURINGEXAM, true);
        const loading = ElLoadingService({
          text: "正在准备测评数据中...",
        });
        const exam = { examType: "exam" };
        getExamPaper(exam).then(() => {
          loading.close();
          if (store.state.authorization) {
            router.push({
              path: "/deviceCheck",
              query: { overType: "goExam" },
            });
          }
        });
      }
    });
    if (path) {
      await router.push({
        path,
      });
    }
  };
  return {
    login,
    loginFromToken,
  };
};
