(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("G2"), require("X6"));
	else if(typeof define === 'function' && define.amd)
		define(["G2", "X6"], factory);
	else if(typeof exports === 'object')
		exports["h5-internal-examination-app"] = factory(require("G2"), require("X6"));
	else
		root["h5-internal-examination-app"] = factory(root["G2"], root["X6"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__7c93__, __WEBPACK_EXTERNAL_MODULE_d15b__) {
return 