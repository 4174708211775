import useLogin from "@/hooks/useLogin";
import { LoginType } from "@/types";
import { MutationNames, useStore } from "@/store";
import { computed } from "vue";

export default () => {
  const jsBridge = computed(() => window.WebViewJavascriptBridge);
  const store = useStore();
  const { loginFromToken } = useLogin();

  const registerHandler = () => {
    console.log("进入registerHandler");
    jsBridge.value?.registerHandler("login", async (data, responseCallback) => {
      const req = JSON.parse(data);
      const type = req.data.type;

      const res = {
        eventCode: 1,
        msg: "success",
      };
      if (type === LoginType.Token || type === LoginType.Username) {
        const token = req.data.token;
        if (token) {
          store.commit(MutationNames.CHANGE_LOGIN_TYPE, type);
          await loginFromToken(token, type);
        } else {
          res.eventCode = 0;
          res.msg = `无效的token: ${token}`;
          responseCallback(res);
          throw new Error(res.msg);
        }
      } else {
        res.eventCode = 0;
        res.msg = `未定义的登录类型: ${type}`;
        responseCallback(res);
        throw new Error(res.msg);
      }
      console.timeEnd("时间评估: 获取token");

      responseCallback(res);
    });
  };
  return {
    registerHandler,
  };
};
