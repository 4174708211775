import { InjectionKey } from "vue";
import { createStore, Store, useStore as baseUseStore } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import mutations, { MutationNames } from "./mutations";
export { MutationNames };

export const key: InjectionKey<Store<State>> = Symbol();
export default createStore<State>({
  state,
  mutations,
  getters,
});

export const useStore = () => baseUseStore(key);
