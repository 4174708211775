import request from "@/utils/request";
import servers from "@/config/servers";
import { updateExamStatus } from "@/services/proctor";

/**
 * request 请求参数
 * (
 *    http method, http 请求方法
 *    request relate path, 请求的相对路径
 *    server, 请求的服务，来源于 servers
 *    params, 请求参数
 *    need token 是否需要 token
 * )
 */

// 学生登录
export async function studentLogin(params: any) {
  return request("POST", "/student/auth/login", servers.user, params);
}

// 获取学生信息
export async function getStudentInfo(params?: any) {
  return request("GET", "/student/auth/me", servers.user, params);
}

// 重置密码
export async function resetPassword(params: any) {
  return request("POST", "/student/auth/resetPassword", servers.user, params);
}

// 获取学生当前可用的正式考试信息
export async function getExamInfo() {
  return request(
    "GET",
    "/student/production-exam/current-active-exam",
    servers.exam
  );
}
// 获取学生当前可用的模拟考试信息
export async function getMockExamInfo() {
  return request(
    "GET",
    "/student/mock-exam/current-active-exams",
    servers.exam
  );
}

// 获取正式考试使用的试卷列表
export async function getPapers(exam_id: number) {
  return request(
    "GET",
    `/student/production-exam/${exam_id}/papers`,
    servers.exam
  );
}
// 获取模拟考试使用的试卷列表
export async function getDrawPaper(exam_id: number) {
  return request(
    "GET",
    `/student/mock-exam/${exam_id}/draw-paper`,
    servers.exam
  );
}

// 获取正式考试试卷
export async function getPaper(params: any, checkLanMode = true) {
  return request(
    "GET",
    `/student/production-exam/paper-structure`,
    servers.exam,
    params,
    true,
    checkLanMode
  );
}

// 获取模拟考试试卷
export async function getMockExamPaper(params: any) {
  return request(
    "GET",
    `/student/mock-exam/paper-structure`,
    servers.exam,
    params,
    true
  );
}

// 开始考试
export async function startExam(exam_id: any, params: any) {
  updateExamStatus({ status: 3 });
  return request(
    "POST",
    `/student/production-exam/${exam_id}/start-exam`,
    servers.exam,
    params
  );
}

// 保存正式考试答案
export async function saveExamAnswer(exam_id: any, params: any) {
  // 给监考机发送答案消息
  updateExamStatus();
  return request(
    "POST",
    `/student/production-exam/${exam_id}/save-exam-answers`,
    servers.exam,
    params,
    true,
    true
  );
}

// 保存模拟考试答案
export async function saveMockExamAnswer(exam_id: any, params: any) {
  return request(
    "POST",
    `/student/mock-exam/${exam_id}/save-exam-answers`,
    servers.exam,
    params,
    true
  );
}

// 结束考试
export async function finishExam(exam_id: any) {
  updateExamStatus({ status: 4 });
  return request(
    "POST",
    `/student/production-exam/${exam_id}/finish-exam`,
    servers.exam
  );
}

// 获取考生已完成的正式考试答卷
export async function getExamAnswers(exam_id: any) {
  return request(
    "GET",
    `/student/production-exam/${exam_id}/exam-answers`,
    servers.exam
  );
}

// 获取考生已完成的模拟考试答卷
export async function getMockExamAnswers(exam_id: any) {
  return request(
    "GET",
    `/student/mock-exam/answer-sheet/${exam_id}`,
    servers.exam
  );
}

// 获取考生已完成的正式考试历史作答记录
export async function getHistoryExam(params: any) {
  return request(
    "GET",
    `/student/production-exam/history-answer-sheets`,
    servers.exam,
    params
  );
}
// 获取考生已完成的模拟考试历史作答记录
export async function getHistoryMockExam(params: any) {
  return request(
    "GET",
    `/student/mock-exam/history-answer-sheets`,
    servers.exam,
    params
  );
}

// 获取官方检索tag
export async function getOfficialTags(params: any) {
  return request("GET", `/official/tags`, servers.exam, params);
}

// 获取学校检索tag
export async function getSchoolTags(params: any) {
  return request("GET", `/school/tags`, servers.exam, params);
}

// 自由练习抽取题目
export async function getDrawQuestionPool(params: any) {
  return request(
    "GET",
    `/student/free-practice/draw-question-pool`,
    servers.exam,
    params
  );
}

// 自由练习题目详情
export async function getQuestionDetail(params: any) {
  return request(
    "GET",
    `/student/free-practice/question-detail`,
    servers.exam,
    params
  );
}

// 自由练习ai盘分
export async function aiJude(params: any) {
  return request(
    "POST",
    `student/free-practice/ai-judge`,
    servers.exam,
    params
  );
}

// 用户登录时检测是否有正式评测在进行中，有的话直接跳到考试页面
export async function getCurrentDuringExam() {
  return request(
    "GET",
    `/student/production-exam/current-during-exam`,
    servers.exam
  );
}

// 获取学校pc测评软件包信息
export async function getSchoolPcSoftwareUpgradeInfo(params: any) {
  return request(
    "GET",
    "/teacher/schoolPcSoftwareUpgradeInfo",
    servers.device,
    params
  );
}
// 获取pc端授权状态
export async function checkPcExamAuthorization(params: any) {
  return request(
    "GET",
    "/device/checkPcExamAuthorization",
    servers.authorization,
    params
  );
}
// 上报设备信息
export async function uploadData(params: any) {
  return request("POST", "/device/report-data", servers.device, params);
}
