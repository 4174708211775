import { LoginType } from "@/types";

export const StateNames = {
  account: "account",
  loginType: "loginType",
  token: "token",
  classNumber: "classNumber",
  grade: "grade",
  name: "name",
  schoolName: "schoolName",
  examInfo: "examInfo",
  MAC: "MAC",
  freePracticeQuestions: "freePracticeQuestions",
  freePracticeRecordSubjectData: "freePracticeRecordSubjectData",
  examError: "examError",
  examEnd: "examEnd",
  mockExamEnd: "mockExamEnd",
  hardwareModel: "hardwareModel",
  authorization: "authorization",
  version: "version",
  isLan: "isLan",
  proctorDomain: "proctorDomain",
  examIp: "examIp",
  isDuringExam: "isDuringExam",
  isExitFluidsynthMidi3: "isExitFluidsynthMidi3",
  studentId: "studentId",
  noLogo: "noLogo",
};
export const state: State = {
  account: "",
  loginType: LoginType.Account,
  token: "",
  classNumber: "",
  grade: "",
  name: "",
  schoolName: "",
  examInfo: undefined,
  papers: undefined,
  MAC: "",
  freePracticeQuestions: [],
  freePracticeRecordSubjectData: [],
  examError: false,
  examEnd: false,
  mockExamEnd: false,
  hardwareModel: "",
  authorization: false,
  version: "",
  isLan: false,
  proctorDomain: "",
  examIp: "",
  isDuringExam: false,
  isExitFluidsynthMidi3: false,
  studentId: 0,
  noLogo: false,
};
