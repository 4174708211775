import _ from "lodash";
import {
  ElMessageBox,
  ElMessageBoxOptions,
  ElMessage,
  ElLoading,
} from "element-plus";
import { useRouter } from "vue-router";
import { MutationNames } from "@/store/index";
import { platform as platformService } from "@/services/platform";

import {
  getPapers,
  getPaper,
  getExamAnswers,
  saveExamAnswer,
  finishExam,
} from "@/services";
import storeOrigin, { useStore } from "@/store";

export default (option?: any) => {
  const store = useStore();
  const router = useRouter();
  let submitLock = false;
  const getExamPaper = async (exam: any, opt?: any) => {
    let examId = undefined;
    let hasAnswer = undefined;
    if (exam.examType === "exam") {
      examId = store.state.examInfo?.active_exam?.id;
      if (!examId) {
        return;
      }
      hasAnswer = !!store.state.examInfo?.answer_sheet;
    } else {
      examId = exam.exam_id;
      if (!examId) {
        return;
      }
      hasAnswer = !!exam;
    }
    const examAnswer = hasAnswer ? (await getExamAnswers(examId)) || {} : {};
    const { answers = [] } = examAnswer;
    const { papers = [] } = (await getPapers(examId!)) || {};
    const findSetAnswer = (question: any, paper: any, answers: any) => {
      question.paper_id = paper.id;
      const answer = answers.find(
        (an: any) => an.paper_id === paper.id && an.question_id === question.id
      );
      if (answer) {
        question.correctness = answer.correctness;
        question.answerScore = answer.score || 0;
        question.answer_params = answer.answer_params;
      }
    };
    for (let i = 0; i < papers.length; i++) {
      const paper = papers[i];
      const parperPart = await getPaper(
        {
          paper_id: paper.id,
        },
        !opt?.ignoreLan
      );

      if (parperPart.parts) {
        parperPart.parts.forEach((part: any) => {
          part.paper_id = paper.id;
          let questionOrderType: any = 0;
          questionOrderType =
            store.state.examInfo?.active_exam?.question_order_type;
          // 当试卷为乱序时题目进行乱序
          if (questionOrderType > 0) {
            const shuffleQuestions = _.shuffle(part.questions);
            part.questions = shuffleQuestions;
          }

          part.questions.forEach((question: any) => {
            // console.log("parperPart3", question);
            // 当试卷为乱序时题目单选多选选项为乱序
            if (questionOrderType > 0) {
              if (
                question.question_params?.options &&
                question.question_params.options.length > 0
              ) {
                const shuffleOptions = _.shuffle(
                  question.question_params.options
                );
                question.question_params.options = shuffleOptions;
              }
              // 题目为套题时单选多选选项乱序
              if (question.type === 11) {
                question.sub_questions.forEach((subQuestion: any) => {
                  // console.log("parperPart4", subQuestion);
                  if (
                    subQuestion.question_params?.options &&
                    subQuestion.question_params.options.length > 0
                  ) {
                    const shuffleOptions = _.shuffle(
                      subQuestion.question_params.options
                    );
                    subQuestion.question_params.options = shuffleOptions;
                  }
                });
              }
            }
            findSetAnswer(question, paper, answers);
            if (question.sub_questions && question.sub_questions.length > 0) {
              question.sub_questions.forEach((subQuestion: any) =>
                findSetAnswer(subQuestion, paper, answers)
              );
            }
          });
        });
        paper.parperParts = parperPart;
      }
    }
    store.commit(MutationNames.CHANGE_PAPERS, papers);
  };
  let submitServiceLock = false;

  const goBack = async (platform = 0) => {
    submitServiceLock = true;
    ElLoading.service({}).close();
    ElMessageBox.close();
    // 判断是否为Android 是的话通知android隐藏悬浮球
    let isAndroid = true;
    const device = navigator.userAgent;
    isAndroid = device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    if (isAndroid) {
      platformService.goLogin();
    } else {
      router.push({
        path: "/login",
      });
    }
    // if (platform == 0) {
    //   router.push({
    //     path: "/login",
    //   });
    // } else {
    //   platformService.goLogin();
    // }
  };

  const submitPapers = async (
    mac: string,
    platform: number,
    timeEnd = false,
    onCancel?: any
  ) => {
    const papers = store.state.papers || [];
    if (papers.length <= 0) {
      return;
    }
    let retryCount = 0;
    const examId = store.state.examInfo?.active_exam?.id;
    const answers: any[] = [];
    const getAllAnswer = () => {
      papers.forEach((paper) => {
        const { parperParts = { parts: [] } } = paper;
        const { parts = [] } = parperParts;
        parts.forEach((part) => {
          const { questions = [] } = part;
          questions.forEach((question) => {
            if (question.sub_questions && question.sub_questions.length > 0) {
              // question.sub_questions.forEach((subQuestion: any) =>
              //   answers.push({
              //     paper_id: paper.id,
              //     question_id: subQuestion.id,
              //     answer_params:
              //       subQuestion.answer_params === undefined
              //         ? {}
              //         : subQuestion.answer_params,
              //   })
              // );

              question.sub_questions.forEach(
                (subQuestion: any) => {
                  if (subQuestion.answer_params !== undefined) {
                    if (subQuestion.answer_params.aiScore !== undefined) {
                      answers.push({
                        paper_id: paper.id,
                        question_id: subQuestion.id,
                        answer_params:
                          subQuestion.answer_params === undefined
                            ? {}
                            : subQuestion.answer_params,
                        ai_raw_score:
                          subQuestion.answer_params.aiScore === undefined
                            ? {}
                            : subQuestion.answer_params.aiScore,
                      });
                    } else {
                      answers.push({
                        paper_id: paper.id,
                        question_id: subQuestion.id,
                        answer_params:
                          subQuestion.answer_params === undefined
                            ? {}
                            : subQuestion.answer_params,
                      });
                    }
                  }
                }
                // subQuestion.answer_params !== undefined &&
                // answers.push({
                //   paper_id: paper.id,
                //   question_id: subQuestion.id,
                //   answer_params:
                //     subQuestion.answer_params === undefined
                //       ? {}
                //       : subQuestion.answer_params,
                // })
              );
            }
            if (question.answer_params !== undefined) {
              if (question.answer_params.aiScore !== undefined) {
                answers.push({
                  paper_id: paper.id,
                  question_id: question.id,
                  answer_params: question.answer_params,
                  ai_raw_score: question.answer_params.aiScore,
                });
              } else {
                answers.push({
                  paper_id: paper.id,
                  question_id: question.id,
                  answer_params:
                    question.answer_params === undefined
                      ? {}
                      : question.answer_params,
                });
              }
            }
          });
        });
      });
    };
    const submitAnswer = async () => {
      console.log("submitServiceLock", submitServiceLock);
      if (submitServiceLock) {
        return;
      }
      submitServiceLock = true;
      // ElMessageBox.close();
      normalMessageBox({
        title: "- 交卷中 -",
        message: `<div style="padding-top: 30px">努力交卷中，请稍后</div>`,
        showCancelButton: false,
        showConfirmButton: false,
      });
      saveExamAnswer(examId, {
        create_device_mac: mac,
        create_device_type: platform,
        answers: answers.map((i) => {
          if (i.answer_params && Object.keys(i.answer_params).length > 0) {
            i.answer_params.update_time = new Date().getTime();
          }
          i.paper_name = store.state.papers?.filter(
            (j) => j.id === i.paper_id
          )[0].name;
          return i;
        }),
        exam_id: examId,
        student_name: store.state.name,
        student_id: store.state.studentId,
        ip: store.state.examIp,
      })
        .then(() => {
          ElMessageBox.close();
          store.commit(MutationNames.CHANGE_EXAMEND, false);
          retryCount = 0;
          ElMessage({
            offset: 150,
            center: true,
            type: "success",
            message: `测评结束，已自动上传答卷`,
            onClose: () => {
              finishExam(examId).then(() => {
                store.commit(MutationNames.CHANGE_ISDURINGEXAM, false);
                store.commit(MutationNames.CHANGE_EXAMINFO, undefined);
                goBack();
              });
            },
          });
        })
        .catch((e) => {
          console.log("交卷 error");
          console.error(e);
          submitServiceLock = false;
          if (retryCount > 2) {
            // 弹框提示
            ElMessageBox.close();
            normalMessageBox({
              title: "- 网络异常 -",
              message: `<div style="padding-top: 30px">交卷失败，请联系监考老师</div>`,
              confirmButtonText: "交卷",
              cancelButtonText: "离开",
            })
              .then(async () => {
                console.log("点击重试");
                ElMessageBox.close();
                await submitAnswer();
              })
              .catch(() => {
                console.log("返回");
                goBack();
              });
            return;
          } else {
            retryCount++;
            setTimeout(async () => {
              if (!retryCount) {
                return;
              }
              ElMessageBox.close();
              await submitAnswer();
              console.log("重试");
            }, 1000);
          }
        })
        .finally(() => {
          submitLock = false;
        });
    };
    if (timeEnd) {
      // 时间到 自动提交
      getAllAnswer();
      await submitAnswer();
      return;
    }

    if (submitLock) {
      return;
    }
    submitLock = true;

    normalMessageBox({
      title: "- 提交试卷 -",
      message: `<div style="padding-top: 30px">确定提交试卷？</div>`,
    })
      .then(() => {
        getAllAnswer();
        const paperQuestions = store.getters.allPaperQuestionsStatistics;

        const answerSituation = paperQuestions.map((p: any) =>
          _.pick(p, [
            "name",
            "total_question_num",
            "total_answer_question_num",
            "paper_group",
          ])
        );

        const answerSum = _.sumBy(
          answerSituation,
          (p: any) => p.total_answer_question_num
        );

        const questionsSum = _.sumBy(
          answerSituation,
          (p: any) => p.total_question_num
        );
        if (answerSum !== questionsSum) {
          const message = answerSituation
            .map(
              (p: any) =>
                `<div style="padding-top: 5px">${
                  p?.paper_group?.name || p.name
                }已作答<span style="color:red">${
                  p.total_answer_question_num
                }</span>题,还剩<span style="color:red">${
                  p.total_question_num - p.total_answer_question_num
                }</span>题未答</>`
            )
            .join("<br/>");
          normalMessageBox({
            title: "- 提交试卷 -",
            message: `<p style="padding-top: 0">${message}</p>`,
          })
            .then(async () => {
              console.log("正常提交");
              await submitAnswer();
            })
            .catch(() => {
              store.commit(MutationNames.CHANGE_EXAMEND, false);
              submitLock = false;
            });
          return;
        }
        submitAnswer();
      })
      .catch(() => {
        onCancel?.();
        submitLock = false;
        store.commit(MutationNames.CHANGE_EXAMEND, false);
      });
  };

  const showExamMessageBox = (
    option: ElMessageBoxOptions & { buttonSize?: string } = {}
  ) => {
    return ElMessageBox({
      title: "- 系统出错 -",
      message: "系统出错，无法进行测评，请联系监考老师处理！",
      customClass: "exam-message-box",
      confirmButtonText: "确定",
      buttonSize: "large",
      center: true,
      ...option,
    });
  };

  const normalMessageBox = (params: any) => {
    const normalParams = {
      type: null,
      title: params.title,
      center: true,
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: params.message,
      showCancelButton: true,
      cancelButtonText: "取消",
      closeOnClickModal: false,
      customClass: "exam-score-message-box2",
    };
    return showExamMessageBox({ ...normalParams, ...params });
  };

  const showExamTipBox = (msg: string) => {
    return ElMessageBox({
      showClose: false,
      dangerouslyUseHTMLString: true,
      title: "- 注意 -",
      message: `<div style="padding-top: 30px">${msg}</div>`,
      customClass: "exam-message-box",
      center: true,
      showConfirmButton: false,
      showCancelButton: false,
    });
  };

  /**
   * 模式切换后，需要保留原答案数据
   * 获取新试卷数据
   * 获取已提交答案
   * 本地答案和已提交答案做对比，本地有新的则上传,
   * 覆盖新数据的answer_params
   */
  const reloadExamPaperWhenModeChange = async () => {
    let examId: number | undefined = undefined;
    let hasAnswer = undefined;
    examId = storeOrigin.state.examInfo?.active_exam?.id;
    if (!examId) {
      return;
    }
    hasAnswer = !!storeOrigin.state.examInfo?.answer_sheet;
    // 获取本地答案
    const localAnswers: any[] = [];
    storeOrigin.state.papers?.map((paper) => {
      paper.parperParts.parts.map((part: any) => {
        part.questions.map((question: any) => {
          if (question.answer_params) {
            localAnswers.push({
              question_id: question.id,
              paper_id: question.paper_id,
              paper_name: paper.name,
              answer_params: question.answer_params,
            });
          }
        });
      });
    });
    console.log("-----localAnswers----", localAnswers);
    const examAnswer = hasAnswer ? (await getExamAnswers(examId)) || {} : {};
    // todo 验证localAnswer 结构 和remote一样
    const remoteAnswers = examAnswer.answers;
    console.log("-----remoteAnswers----", remoteAnswers);

    // 获取待上传答案 todo 优化
    const unSaveAnswers = _.differenceWith(
      localAnswers,
      remoteAnswers,
      (localItem, item: any) => {
        if (localItem.question_id !== item?.question_id) {
          return false;
        }
        if (
          (localItem?.answer_params?.update_time || 0) >
          (item.answer_params.update_time || 0)
        ) {
          return false;
        }
        return true;
      }
    );
    // 覆盖新答案 并对比出本地未上传的数据进行上传
    console.log("-----unSaveAnswers----", unSaveAnswers);
    const answers = localAnswers;
    const { papers = [] } = (await getPapers(examId!)) || {};
    const findSetAnswer = (question: any, paper: any, answers: any) => {
      question.paper_id = paper.id;
      const answer = answers.find(
        (an: any) => an.paper_id === paper.id && an.question_id === question.id
      );
      if (answer) {
        question.correctness = answer.correctness;
        question.answerScore = answer.score || 0;
        question.answer_params = answer.answer_params;
      }
    };
    for (let i = 0; i < papers.length; i++) {
      const paper = papers[i];
      const parperPart = await getPaper({
        paper_id: paper.id,
      });
      if (parperPart.parts) {
        parperPart.parts.forEach((part: any) => {
          part.paper_id = paper.id;
          part.questions.forEach((question: any) => {
            findSetAnswer(question, paper, answers);
            if (question.sub_questions && question.sub_questions.length > 0) {
              question.sub_questions.forEach((subQuestion: any) =>
                findSetAnswer(subQuestion, paper, answers)
              );
            }
          });
        });
        paper.parperParts = parperPart;
      }
    }
    storeOrigin.commit(MutationNames.CHANGE_PAPERS, papers);
    // 上传unSaveAnswers
    const device = navigator.userAgent;
    const isAndroid =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    const platformCode = isAndroid ? 1 : 0;
    unSaveAnswers.map((i) => {
      const saveExamAnswerInfo: any = {};
      saveExamAnswerInfo.create_device_mac = storeOrigin.state.MAC || "abcdefg";
      saveExamAnswerInfo.create_device_type = platformCode;
      // todo 验证二者结构相等
      saveExamAnswerInfo.answers = [i];
      saveExamAnswerInfo.exam_id = examId;
      saveExamAnswerInfo.student_name = storeOrigin.state.name;
      saveExamAnswerInfo.ip = storeOrigin.state.examIp;
      saveExamAnswer(examId, saveExamAnswerInfo);
    });
  };

  return {
    getExamPaper,
    submitPapers,
    showExamMessageBox,
    showExamTipBox,
    reloadExamPaperWhenModeChange,
  };
};
