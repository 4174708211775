import useJsBridge from "@/hooks/useJsBridge";

function timeoutReject(reject: (error: Error) => void, type: any) {
  return setTimeout(function () {
    reject(new Error("调用 android js bridge 超时!" + type));
  }, 5000);
}

export const androidPlatform: AndroidPlatformInterface = {
  setOnExamEnter(): Promise<string> {
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject, "1");
      window.WebViewJavascriptBridge.callHandler(
        "onExamEnter",
        {},
        (responseData) => {
          clearTimeout(to);
          resolve(responseData);
        }
      );
    });
  },
  getVolume(): Promise<number> {
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject, "2");
      window.WebViewJavascriptBridge.callHandler(
        "getVolume",
        {},
        (responseData) => {
          clearTimeout(to);
          const response: AndroidResponse = JSON.parse(responseData);
          if (response.eventCode === 0) {
            reject(response.message);
            return;
          }
          resolve(response.data.volume);
        }
      );
    });
  },
  setVolume(volume: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject, "2");
      window.WebViewJavascriptBridge.callHandler("setVolume", volume, () => {
        clearTimeout(to);
        resolve();
      });
    });
  },
  getMicVolume(): Promise<number> {
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject, "3");
      window.WebViewJavascriptBridge.callHandler(
        "getMicVolume",
        {},
        (responseData) => {
          clearTimeout(to);
          const response: AndroidResponse = JSON.parse(responseData);
          if (response.eventCode === 0) {
            reject(response.message);
            return;
          }
          resolve(response.data.volume);
        }
      );
    });
  },
  setMicVolume(volume: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject, "4");
      window.WebViewJavascriptBridge.callHandler(
        "setMicVolume",
        volume,
        (responseData) => {
          clearTimeout(to);
          resolve(responseData);
        }
      );
    });
  },
  getMac(): Promise<string> {
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject, "5");
      window.WebViewJavascriptBridge.callHandler(
        "getMac",
        {},
        (responseData) => {
          clearTimeout(to);
          resolve(responseData);
        }
      );
    });
  },
  exitWebView: function () {
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject, "6");
      window.WebViewJavascriptBridge.callHandler("exitWebView", {}, () => {
        clearTimeout(to);
        console.log("Exit WebView success!");
      });
    });
  },
  goLogin: function () {
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject, "7");
      // todo 需要联调跳转到登录界面的安卓接口
      window.WebViewJavascriptBridge.callHandler("onExamExit", {}, () => {
        clearTimeout(to);
        console.log("go login success!");
      });
    });
  },
  closeApp(): Promise<string> {
    return new Promise((resolve, reject) => {
      console.log("go login success!");
    });
  },

  ready(): Promise<void> {
    console.log("进入ready");
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject, "8");
      window.WebViewJavascriptBridge.callHandler("ready", {}, () => {
        console.log("发送了ready");
        clearTimeout(to);
        resolve();
      });
    });
  },

  registerHandles: function () {
    console.log("初始化加载hen");
    const { registerHandler } = useJsBridge();

    const s = 123;
    console.log("1初始化加载");
    if (window.WebViewJavascriptBridge) {
      console.log("2初始化加载");
      registerHandler();
    } else {
      console.log("3初始化加载");
      document.addEventListener(
        "WebViewJavascriptBridgeReady",
        () => {
          registerHandler();
        },
        {
          once: true,
        }
      );
    }
  },
  setMuted: function (): Promise<void> {
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject, "10");
      window.WebViewJavascriptBridge.callHandler("setVolume", 0, () => {
        clearTimeout(to);
        resolve();
      });
    });
  },
  listenProctorConfig(): void {
    return;
  },
};
