
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
import {
  ElMessage,
  ElMessageBox,
  ElLoading,
  ElLoadingService,
} from "element-plus";
import useLogin from "@/hooks/useLogin";
import { platform } from "@/services/platform";
import _ from "lodash";
import { MutationNames, useStore } from "@/store";

export default defineComponent({
  setup() {
    const { login } = useLogin();
    const store = useStore();
    let account = ref("");
    let password = ref("");
    const router = useRouter();
    let loading = ref(false);
    let loadingText = ref("登录中...");

    const btnLogin = _.throttle(async () => {
      // loading.value = ElLoading.service({
      //   fullscreen: true,
      //   lock: true,
      //   text: "登录中...",
      //   background: "rgba(0, 0, 0, 0.3)",
      // });
      // loading.value = true;
      if (!account.value || !password.value) {
        // loading.value.close();
        // setTimeout(() => {
        //   loading.value = false;
        // }, 2000);
        ElMessage({
          type: "warning",
          offset: 100,
          message: "请输入账号，密码进行登录",
          center: true,
        });
        return;
      }
      login(
        {
          account: account.value,
          password: password.value,
        },
        "/home"
      );
    }, 2000);
    const goToDeviceCheck = () => {
      // const loading = ElLoadingService({
      //   text: "加载中...",
      // });
      loadingText.value = "加载中...";
      loading.value = true;
      setTimeout(() => {
        loading.value = false;
      }, 500);
      router.push({
        path: "/deviceCheck",
      });
    };

    const closeApp = _.throttle(
      async () => {
        ElMessageBox.confirm(
          `<div style="height: 100px; line-height:100px; font-size:24px">确定要关闭软件吗？</div>`,
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            center: true,
            showClose: false,
            dangerouslyUseHTMLString: true,
          }
        )
          .then(async () => {
            console.log(`通知关闭软件`);
            await platform.closeApp();
          })
          .catch(() => {
            console.log("取消");
          });
      },
      500,
      { leading: true }
    );

    onBeforeMount(async () => {
      // 获取mac
      const mac = await platform.getMac();
      console.log("h5MAc", mac);
      if (mac) {
        store.commit(MutationNames.CHANGE_MAC, mac);
      }
      store.commit(MutationNames.CHANGE_EXAMEND, false);
      store.commit(MutationNames.CHANGE_EXAMERROE, false);
      console.log("loginexamError--->", store.state.examError);
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.once("hardwareModel", (event: any, message: any) => {
          // 接收electron原生返回的信息
          console.log(
            "我接收到的electron发过来的信息hardwareModel",
            event,
            message
          );
          store.commit(MutationNames.CHANGE_HARDWAREMODEL, message);
        });

        if (window.platform !== "android") {
          ipcRenderer
            .invoke("isExitFluidsynthMidi3")
            .then((isExit: boolean) => {
              if (isExit) {
                console.log("客户端为高版本 存在音色库文件");
                store.commit(MutationNames.CHANGE_ISEXITFLUIDSYNTHMIDI3, true);
              } else {
                console.log("客户端为高版本 但音色库文件不存在");
                store.commit(MutationNames.CHANGE_ISEXITFLUIDSYNTHMIDI3, false);
              }
            })
            .catch(() => {
              console.log("客户端为低版本 不存在音色库文件");
              store.commit(MutationNames.CHANGE_ISEXITFLUIDSYNTHMIDI3, false);
            });
        }
      }
    });
    return {
      store,
      account,
      password,
      btnLogin,
      goToDeviceCheck,
      closeApp,
      loading,
      loadingText,
    };
  },
});
