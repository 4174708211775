
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { listenExamMode } from "@/services/proctor";
import { useStore, MutationNames } from "@/store";

export default defineComponent({
  name: "App",
  setup() {
    const store = useStore();
    const keepAlive = ref();
    const version = ref();
    const getVersion = () => {
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.once("version", async (event: any, message: any) => {
          // 接收electron原生返回的信息
          console.log("我接收到的electron发过来的信息version", event, message);
          version.value = message;
          (window as any).h5Version = message;
          store.commit(MutationNames.CHANGE_VERSION, message);
        });
      }
    };
    const getNoLogo = () => {
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.on("noLogo", async (event: any, message: any) => {
          // 接收electron原生返回的信息
          console.log("我接收到的electron发过来的信息getNoLogo", message);
          store.commit(MutationNames.CHANGE_NOLOGO, message);
        });
      }
    };
    onMounted(() => {
      listenExamMode();
      getVersion();
      getNoLogo();
    });
    const route = useRoute();
    return { route, keepAlive, store };
  },
});
