import { OssResource, UploadOption } from "@evideo/oss-resource";
import BMF from "browser-md5-file";
import _ from "lodash";
import router from "@/router";
import { MutationNames, useStore } from "@/store";
import { platform as platformService } from "@/services/platform";
import { ElMessage, ElLoading } from "element-plus";
import { uploadByForm } from "@/utils/fileUpload";

export interface UpLoadReply {
  url: string;
  md5: string;
  size: number;
}
let ossResource: OssResource | undefined;

export default () => {
  const store = useStore();
  const createOssResource = async (): Promise<undefined | OssResource> => {
    // todo 需要更改token和上传的地址
    if (ossResource) {
      // || !store.state.token
      return;
    }
    ossResource = new OssResource({
      host: process.env.VUE_APP_RESOURCE_CENTER_HOST || "",
      retryMax: 3,
      token: store.state.token,
      isNodeEnv: false,
      fetch: null,
    });
    try {
      await ossResource.init();
    } catch (error: any) {
      console.log("error", error);
      store.commit(MutationNames.CHANGE_EXAMERROE, true);
      console.log("useexamError--->", store.state.examError);
      let isAndroid = true;
      const device = navigator.userAgent;
      isAndroid = device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;

      if (error.status_code === 401) {
        ElLoading.service({}).close();
        if (isAndroid) {
          console.log("init通知android");
          platformService.goLogin();
        } else {
          console.log("init通知pc");
          router.push({
            path: "/login",
          });
        }
      } else {
        throw error;
      }
    }
  };

  const destoryOssResource = async () => {
    ossResource = undefined;
  };

  const md5 = (file: File): Promise<string> => {
    const bmf = new BMF();
    return new Promise((resolve, reject) => {
      bmf.md5(file, (err: Error, md5: string) => {
        if (err) {
          reject(err);
        }
        resolve(md5);
      });
    });
  };

  /**
   * 通用资源上传，例如图片，音频，视频
   * - 不需要 override
   *
   * @param file
   * @param key
   * @param uploadOption
   * @param uploadProctor
   */
  const upload = async (
    file: File,
    key?: string,
    uploadOption: UploadOption & { preUpload: boolean } = {
      cache: true,
      override: true,
      preUpload: false,
    },
    uploadProctor?: false
  ): Promise<UpLoadReply> => {
    const size = file.size;

    // 计算文件md5
    const md5String = await md5(file);

    // 上传资源的key
    key = `${key ? key + "/" : ""}${md5String}/${file.name}`;
    // 上传资源
    let url: any = "";
    if (!uploadOption.preUpload) {
      if (uploadProctor && store.state.isLan) {
        // 上传到监考机
        try {
          const res: any = await uploadByForm(file, key);
          url = JSON.parse(res).remote_url;
        } catch (e) {
          ElLoading.service({ background: "rgb(0,0,0,0.0)" }).close();
          ElMessage({
            offset: 150,
            center: true,
            type: "warning",
            message: `文件上传错误`,
          });
          console.log("上传失败", e);
          let isAndroid = true;
          const device = navigator.userAgent;
          isAndroid =
            device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;

          if (e.status_code === 401) {
            ElLoading.service({}).close();
            if (isAndroid) {
              console.log("通知android");
              platformService.goLogin();
            } else {
              console.log("通知pc");
              router.push({
                path: "/login",
              });
            }
          }
          throw e;
        }
      } else {
        await createOssResource();
        url = await ossResource
          ?.upload(key, file, _.omit<UploadOption>(uploadOption, ["preUpload"]))
          .catch((err) => {
            console.log("上传失败", err);
            let isAndroid = true;
            const device = navigator.userAgent;
            isAndroid =
              device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;

            if (err.status_code === 401) {
              ElLoading.service({}).close();
              if (isAndroid) {
                console.log("通知android");
                platformService.goLogin();
              } else {
                console.log("通知pc");
                router.push({
                  path: "/login",
                });
              }
            }
            throw err;
          });
      }
    }
    return {
      url,
      md5: md5String,
      size,
    };
  };

  /**
   * 取消上传
   * @param name
   * @param uploadId
   */
  const abort = async (name: string, uploadId: string) => {
    return await ossResource?.abort(name, uploadId);
  };

  /**
   *  将base64转换为文件
   * @param dataurl
   * @param filename
   * @returns
   */
  const dataURLtoFile = (dataurl: string, filename: string) => {
    const arr = dataurl.split(",") || [];
    const mime = (arr[0].match(/:(.*?);/) || [])[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {
      type: mime,
      lastModified: Date.now(),
    });
  };

  return {
    createOssResource,
    destoryOssResource,
    md5,
    upload,
    abort,
    dataURLtoFile,
  };
};
