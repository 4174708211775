export default () => {
  return new Promise<void>((resolve) => {
    // const host = process.env.VUE_APP_HOST;
    console.time("时间评估: loadSheetMusicLib");
    const SheetNote = window?.SheetMusic?.Note;
    const firstLibs = [`js/svg.min.js`, `js/resource.js`];
    const loadSheetDisplay = () => {
      return new Promise<void>((resolve) => {
        const isAndroid =
          navigator.userAgent.indexOf("Android") > -1 ||
          navigator.userAgent.indexOf("Adr") > -1;
        let displayFileName = "display.js";
        if (isAndroid) {
          if ((window as any).useES5 === false) {
            displayFileName = "display.js";
          } else {
            displayFileName = "display.es5.js";
          }
        }
        window.loadjs(`js/${displayFileName}`, function () {
          window.loadjs(`js/evideo-sheet-music-business.umd.js`, () => {
            resolve();
          });
        });
      });
    };
    if (window.SVG && window.Display && window.SheetMusicBusiness) {
      resolve();
    } else {
      if (!SheetNote) {
        firstLibs.push(`js/sheetmusic.js`);
        window.loadjs(firstLibs, () => {
          window.SheetMusic.onRuntimeInitialized(() => {
            loadSheetDisplay().then(() => {
              console.log("window", window);
              console.timeEnd("时间评估: loadSheetMusicLib");

              resolve();
            });
          });
        });
      } else {
        window.loadjs(firstLibs, () => {
          loadSheetDisplay().then(() => {
            console.log("window", window);
            console.timeEnd("时间评估: loadSheetMusicLib");

            resolve();
          });
        });
      }
    }
  });
};
