import proctorRequest from "@/utils/proctorRequest";
import store, { MutationNames } from "@/store";
import { ElMessage, ElMessageBox } from "element-plus";
import { platform } from "@/services/platform";
import useExam from "@/hooks/useExam";

const { reloadExamPaperWhenModeChange } = useExam();

/**
 * 监考机局域网模式下的服务
 */

export const bindProctor = () => {
  return proctorRequest("GET", "bind", {
    mac: store.state.MAC,
    ip: store.state.examIp,
  });
};

let timer = 0;

// 变更考试状态
export const updateExamStatus = (params = {}) => {
  if (!store.state.isLan) {
    return;
  }
  const papers = store.getters.allPaperQuestionsStatistics;
  const answersTotal = papers.reduce(
    (prev: any, cur: { total_question_num: any }) => {
      return prev + cur.total_question_num;
    },
    0
  );
  const answersQuestions = papers.reduce(
    (prev: any, cur: { total_answer_question_num: any }) => {
      return prev + cur.total_answer_question_num;
    },
    0
  );
  return proctorRequest("POST", "/update_terminal_status", {
    data: {
      mac: store.state.MAC,
      ip: store.state.examIp,
      exam_id: store.state.examInfo?.active_exam?.id || 0,
      student_name: store.state.name,
      student_id: store.state.studentId,
      status: store.state.isDuringExam ? 3 : 2,
      answers_total: answersTotal,
      answers_count: answersQuestions,
      ...params,
    },
  });
};

// 监考局域网服务
export const startHeartBeat = (handleStop: any) => {
  timer = window.setInterval(async () => {
    try {
      await proctorRequest("GET", "/");
    } catch (e) {
      console.error(e);
      if (handleStop) {
        handleStop();
      }
      stopHeartBeat();
    }
  }, 1000);
};

export const stopHeartBeat = () => {
  clearInterval(timer);
};

export const startInternetMode = async () => {
  if (store.state.isLan) {
    // 弹出提示框
    ElMessageBox.close();
    ElMessageBox({
      title: "- 与监考端断开连接 -",
      message:
        "<div>正在尝试重新连接，请稍后</div>" +
        '<div class="internet-mode-modal-sub-message">若长时间无法恢复连接，请在和监考老师沟通后，点击"切换到互联网模式"按钮</div>',
      dangerouslyUseHTMLString: true,
      customClass: "exam-message-box",
      confirmButtonText: "切换到互联网模式",
      center: true,
      showClose: false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      closeOnHashChange: false,
      roundButton: true,
      confirmButtonClass: "internet-mode-modal-btn",
    });
  }
  store.commit(MutationNames.CHANGE_ISLAN, false);
  store.commit(MutationNames.CHANGE_PROCTORDOMAIN, "");
  await reloadExamPaperWhenModeChange();
  // setTimeout(() => {
  //   console.log("reloadExamPaperWhenModeChange结束");
  //   ElMessageBox.close();
  // }, 1000);
};

export const listenExamMode = () => {
  console.log("-----listenExamMode-----");
  platform.listenProctorConfig(async (e: any, data: any) => {
    console.log("监听electron主机发来的配置", data);
    // 获取监考ip，切换局域网模式
    const { domain, isLanMode, examIp, examMac } = data;
    console.log(
      "-----store.state.proctorDomain-----",
      store.state.proctorDomain,
      isLanMode
    );
    if (isLanMode) {
      if (store.state.proctorDomain === domain) {
        return;
      }
      ElMessage({
        type: "success",
        center: true,
        message: `已切换为局域网模式`,
      });
      store.commit(MutationNames.CHANGE_ISLAN, true);
      store.commit(MutationNames.CHANGE_PROCTORDOMAIN, domain);
      store.commit(MutationNames.CHANGE_EXAMIP, examIp);
      store.commit(MutationNames.CHANGE_MAC, examMac);
      updateExamStatus();
      await reloadExamPaperWhenModeChange();
      setTimeout(() => {
        console.log("reloadExamPaperWhenModeChange结束");
        ElMessageBox.close();
      }, 2000);
    } else {
      startInternetMode();
    }
  });
};
