// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Upload } from "tus-js-client";
import { getFileMd5 } from "@/utils/fileUpload/utils";
import store from "@/store";

// 定时器
let ticker: any;
// 过期时间
const tokenTimeOut = 1000 * 60 * 60;
const successList: any[] = [];
const errorList: any[] = [];

// {tokenTiemOut}时长后过期
// 定时自动清理token
const invalidToken = (config: any, updateConfig: any) => {
  ticker = setInterval(() => {
    if (config) {
      config = undefined;
      updateConfig(undefined);
      clearInterval(ticker);
    }
  }, tokenTimeOut);
};

async function uploadByForm(file: any, path: string) {
  return new Promise((resolve, reject) => {
    // 获取所选文件的列表

    // 创建一个新的 FormData
    const formData = new FormData();

    formData.append(file.name, file, file.name);

    // 创建新的 Ajax 请求
    const req = new XMLHttpRequest();
    req.timeout = 300000;
    req.open(
      "POST",
      `${store.state.proctorDomain}/evaluation-upload?path=${path}&token=${store.state.token}`,
      true
    );
    // 处理事件
    req.onload = function () {
      if (req.status >= 200 && req.status < 400) {
        resolve(req.responseText);
      } else {
        reject();
      }
    };
    req.onerror = function (e) {
      reject(e);
    };
    req.ontimeout = function () {
      reject();
    };

    // 发送它
    req.send(formData);
  });
}

export { uploadByForm };
