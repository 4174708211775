import { MutationTree } from "vuex";

const MutationNames = {
  CHANGE_ACCOUNT: "changeAccount",
  CHANGE_LOGIN_TYPE: "changeLoginType",
  CHANGE_TOKEN: "changeToken",
  CHANGE_CLASSNUMBER: "changeClassNumber",
  CHANGE_GRADE: "changeGrade",
  CHANGE_NAME: "changeName",
  CHANGE_SCHOOLNAME: "changeSchoolName",
  CHANGE_EXAMINFO: "changeExamInfo",
  CHANGE_PAPERS: "changePapers",
  CHANGE_MAC: "changeMAC",
  CHANGE_FREEPRACTICEQUESTIONS: "changeFreePracticeQuestions",
  CHANGE_FREEPRACTICERECORDSUBJECTDATA: "changeFreePracticeRecordSubjectData",
  CHANGE_EXAMERROE: "changeExamError",
  CHANGE_EXAMEND: "changeExamEnd",
  CHANGE_MOCKEXAMEND: "changeMockExamEnd",
  CHANGE_HARDWAREMODEL: "changeHardwareModel",
  CHANGE_AUTHORIZATION: "changeAuthorization",
  CHANGE_ISLAN: "changeIsLan",
  CHANGE_PROCTORDOMAIN: "changeProctorDomain",
  CHANGE_EXAMIP: "changeExamIp",
  CHANGE_ISDURINGEXAM: "changeIsDuringExam",
  CHANGE_ISEXITFLUIDSYNTHMIDI3: "changeIsExitFluidsynthMidi3",
  CHANGE_STUDENTID: "changeStudentId",
  CHANGE_VERSION: "changeVersion",
  CHANGE_NOLOGO: "changeNoLogo",
};
const mutations: MutationTree<State> = {
  changeAccount(state, account) {
    state.account = account;
  },
  changeLoginType(state, loginType) {
    state.loginType = loginType;
  },
  changeToken(state, token) {
    state.token = token;
  },
  changeClassNumber(state, classNumber) {
    state.classNumber = classNumber;
  },
  changeGrade(state, grade) {
    state.grade = grade;
  },
  changeName(state, name) {
    state.name = name;
  },
  changeSchoolName(state, schoolName) {
    state.schoolName = schoolName;
  },
  changeExamInfo(state, params) {
    state.examInfo = params;
  },
  changePapers(state, params) {
    state.papers = params;
  },
  changeMAC(state, mac) {
    state.MAC = mac;
  },
  changeFreePracticeQuestions(state, freePracticeQuestions) {
    state.freePracticeQuestions = freePracticeQuestions;
  },
  changeFreePracticeRecordSubjectData(state, freePracticeRecordSubjectData) {
    state.freePracticeRecordSubjectData = freePracticeRecordSubjectData;
  },
  changeExamError(state, examErrorState) {
    state.examError = examErrorState;
  },
  changeExamEnd(state, examEndState) {
    state.examEnd = examEndState;
  },
  changeMockExamEnd(state, mockExamEndState) {
    state.mockExamEnd = mockExamEndState;
  },
  changeHardwareModel(state, hardwareModel) {
    state.hardwareModel = hardwareModel;
  },
  changeAuthorization(state, value) {
    state.authorization = value;
  },
  changeIsLan(state, value) {
    localStorage.setItem("isLan", value);
    state.isLan = value;
  },
  changeProctorDomain(state, value) {
    state.proctorDomain = value;
  },
  changeExamIp(state, value) {
    state.examIp = value;
  },
  changeIsDuringExam(state, value) {
    state.isDuringExam = value;
  },
  changeIsExitFluidsynthMidi3(state, isExitFluidsynthMidi3) {
    state.isExitFluidsynthMidi3 = isExitFluidsynthMidi3;
  },
  changeStudentId(state, v) {
    state.studentId = v;
  },
  changeVersion(state, v) {
    state.version = v;
  },
  changeNoLogo(state, v) {
    state.noLogo = v;
  },
};

export { MutationNames };
export default mutations;
