console.log("start");
// (window.WebViewJavascriptBridge as any) = true;
console.log(window.WebViewJavascriptBridge);
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store, { key } from "./store";
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "element-plus/dist/index.css";
import "@/assets/css/global.scss";
// import useCreateSandBox from "./hooks/useCreateSandBox";
import loadSheetMusic from "./utils/loadSheetMusicLib";
import "./utils/rem";

// const { createAndroidMusicScoreSandbox } = useCreateSandBox();
// createApp(App).use(store).use(router).mount("#app");
const render = async () => {
  loadSheetMusic();
  const app = createApp(App);
  app.use(ElementPlus, {
    locale: zhCn,
  });
  app.use(store, key);
  app.use(router);
  // createAndroidMusicScoreSandbox(app);
  app.mount("#app");
};

render();
