import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Login from "../views/login/Login.vue";
import { nextTick } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/login",
    name: "UseLogin",
    component: () => import("../views/login/Login.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/home/Home.vue"),
  },
  {
    path: "/examPaperList",
    name: "ExamPaperList",
    component: () => import("../views/examPaperList/ExamPaperList.vue"),
  },
  {
    path: "/mockExamPaperList",
    name: "MockExamPaperList",
    component: () => import("../views/mockExamPaperList/MockExamPaperList.vue"),
  },
  {
    path: "/freeMode",
    name: "FreeMode",
    component: () => import("../views/freeMode/FreeMode.vue"),
  },
  {
    path: "/unifiedMode",
    name: "UnifiedMode",
    component: () => import("../views/unifiedMode/unifiedMode.vue"),
  },
  {
    path: "/examRecord",
    name: "ExamRecord",
    component: () => import("../views/examRecord/ExamRecord.vue"),
  },
  {
    path: "/deviceCheck",
    name: "DeviceCheck",
    component: () => import("../views/deviceCheck/DeviceCheck.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("../components/NestingQuestion.vue"),
  },
  {
    path: "/paper",
    name: "Paper",
    component: () => import("../views/paper/Paper.vue"),
  },
  {
    path: "/freePractice",
    name: "FreePractice",
    component: () => import("../views/freePractice/FreePractice.vue"),
    meta: {
      title: "FreePractice",
      keepAlive: true,
    },
  },
  {
    path: "/freePracticeRecord",
    name: "FreePracticeRecord",
    component: () =>
      import("../views/freePracticeRecord/FreePracticeRecord.vue"),
  },
  {
    path: "/mockPaper",
    name: "MockPaper",
    component: () => import("../views/mockPaper/MockPaper.vue"),
  },
  {
    path: "/mockPaper/history",
    name: "MockPaperHistory",
    component: () => import("../views/mockPaper/MockPaper.vue"),
  },
  {
    path: "/mockPaperHistory",
    name: "MockPaperHistory",
    component: () => import("../views/mockPaperHistory/MockPaperHistory.vue"),
  },
  {
    path: "/freePracticeRecordSubject",
    name: "FreePracticeRecordSubject",
    component: () =>
      import(
        "../views/freePracticeRecordSubject/FreePracticeRecordSubject.vue"
      ),
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("../views/Test.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// const DEFAULT_TITLE = "h5-internal-examination";
// router.beforeEach(async (to) => {
//   await nextTick(() => {
//     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//     // @ts-ignore
//     document.title = to.name || DEFAULT_TITLE;
//   });
// });

export default router;
