const baseSize = 16;
// 设置 rem 函数
function setRem() {
  // 当前页面屏幕分辨率相对于 1920宽的缩放比例，可根据自己需要修改
  const scale = document.documentElement.clientWidth / 1920;
  // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
  document.documentElement.style.fontSize = baseSize * scale + "px";
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem();
};

/**
 * px转为rem的比例
 */
export const getRemRate = () => {
  return 1 / baseSize;
};
export const getScaleRate = () => {
  return document.documentElement.clientWidth / 1920;
};

export function getImageSize(url: string): Promise<number> {
  return new Promise((resolve) => {
    const img = document.createElement("img");
    img.src = url;
    img.onload = () => {
      // 为什么要写 onload  是因为要等他加载完之后才能获取到图片宽高
      resolve(img.naturalWidth); //  2064,4608
    };
  });
}
