function timeoutReject(reject: (error: Error) => void) {
  return setTimeout(function () {
    reject(new Error("调用 electron ipcRender 超时!"));
  }, 5000);
}

function setTimeoutReject(reject: (error: Error) => void, time = 5000) {
  return setTimeout(function () {
    reject(new Error("调用 electron ipcRender 超时!"));
  }, time);
}

let ipcRenderer: any;
if (window.require) {
  ipcRenderer = window.require("electron").ipcRenderer;
  ipcRenderer.sendSyncMirror = ipcRenderer.sendSync;
  ipcRenderer.sendSync = (...args: any[]) => {
    console.log(`ipcRenderer.sendSync 被调用；key:${args[0]}`, args);
    return ipcRenderer.sendSyncMirror(...args);
  };
}

export const electronPlatform: PlatformInterface = {
  getVolume(): Promise<number> {
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject);
      const volume = ipcRenderer.sendSync("getVolume");
      clearTimeout(to);
      resolve(volume);
    });
  },
  setVolume(volume: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject);
      ipcRenderer.sendSync("setVolume", volume);
      clearTimeout(to);
      resolve();
    });
  },
  setMuted(isMuted: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject);
      ipcRenderer.sendSync("setMuted", isMuted);
      clearTimeout(to);
      resolve();
    });
  },
  getMicVolume(): Promise<number> {
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject);
      const volume = ipcRenderer.sendSync("getMicVolume");
      clearTimeout(to);
      resolve(volume);
    });
  },
  setMicVolume(volume: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject);
      ipcRenderer.sendSync("setMicVolume", volume);
      clearTimeout(to);
      resolve();
    });
  },
  getMac(): Promise<string> {
    return new Promise((resolve, reject) => {
      const to = timeoutReject(reject);
      const volume = ipcRenderer.sendSync("getMac");
      clearTimeout(to);
      resolve(volume);
    });
  },
  closeApp(): Promise<string> {
    return new Promise((resolve, reject) => {
      console.log("发送关闭");
      const to = timeoutReject(reject);
      ipcRenderer.sendSync("closeApp");
      clearTimeout(to);
      resolve("");
    });
  },
  ready(): Promise<void> {
    return Promise.resolve();
  },
  setOnExamEnter(): Promise<string> {
    return new Promise((resolve, reject) => {
      // resolve("");
    });
  },
  registerHandles(): void {
    //
  },
  listenProctorConfig(onMessage: any): void {
    ipcRenderer.on("proctorConfig", (event: any, message: any) => {
      // 接收electron原生返回的信息
      console.log("我接收到的electron发过来的信息", event, message);
      if (onMessage) {
        onMessage(event, message);
      }
    });
  },
  getFluidsynthJsSoundFont(): Promise<any> {
    return new Promise((resolve, reject) => {
      const to = setTimeoutReject(reject, 2000);
      ipcRenderer.sendToHost("getFluidsynthMidi3");
      clearTimeout(to);
      const toGet = setTimeoutReject(reject, 2000);
      ipcRenderer.on("FluidsynthMidi3Data", (event: any, data: any) => {
        console.log("我接受到来自electron发送的音色库数据：", data);
        clearTimeout(toGet);
        resolve(data);
      });
    });
  },
};
