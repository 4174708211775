import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mode-info"
}
const _hoisted_2 = {
  key: 1,
  class: "mode-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, { ref: "keepAlive" }, [
          (_ctx.route.meta.keepAlive)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                key: _ctx.route.name
              }))
            : _createCommentVNode("", true)
        ], 1536)),
        (!_ctx.route.meta.keepAlive)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.store.state.proctorDomain)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " 局域网模式 " + _toDisplayString(_ctx.store.state.proctorDomain), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, "互联网模式"))
  ]))
}