import { androidPlatform } from "./android";
import { electronPlatform } from "./electron";
import { defaultPlatform } from "./default";
import { computed } from "vue";
import { key } from "@/store";

let proxy: any;
console.log("window.platform安卓", window, window.platform);
if (window.platform === "android") {
  // android
  console.log("开始调用安卓");
  proxy = androidPlatform;
} else if (window.require) {
  // electron
  proxy = electronPlatform;
} else {
  console.log("开始调用默认");
  proxy = defaultPlatform;
}

export const platform1: PlatformInterface & AndroidPlatformInterface = {
  ...proxy,
};

export const platform = new Proxy<
  (PlatformInterface & AndroidPlatformInterface) | any
>(
  {},
  {
    get(_, key) {
      console.log("调用的key", key);
      let proxy: any;
      if (window.platform === "android") {
        // android
        console.log("开始调用安卓1");
        proxy = androidPlatform;
      } else if (window.require) {
        // electron
        console.log("开始调用electron1");
        proxy = electronPlatform;
      } else {
        console.log("开始调用默认1");
        proxy = defaultPlatform;
      }
      return proxy[key];
    },
  }
);
